import React from 'react';

import { Modal } from 'antd';

const ModalWhatWeDo = (props) => {

  const { handleOk, handleCancel, isModalOpen, data } = props;
  console.log(data)

  return (
    <div >
      <Modal visible={isModalOpen} onOk={handleOk} onCancel={handleCancel} width={450} 
        wrapClassName='modal-what-we-do'
      >
        <div className='we-do-container'>
          <img src={data.image} className='sub-do-image' alt={data.title}/>
          <div className='sub-do-title'>{data.title}</div>
          <div className='sub-do-desc'>What we're known for</div><br />
          <ul>
            {
              data.subDesc?.map((dt, i) => {
                return (
                  <li span={12} key={i}>
                    <div className='sub-desc-title'>{dt.title}</div>
                    <div className='sub-desc-desc'>{dt.description}</div>
                  </li>
                )
              })
            }
          </ul>
        </div>
      </Modal>
    </div>
  );
};

export default ModalWhatWeDo;
