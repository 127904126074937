import React from "react";
import { Layout, Row, Col } from "antd";
import { Link } from "react-router-dom";

const { Footer } = Layout;

const FooterSection = () => {
  return (
    <div className="footer-section">
      <Footer className="top">
        {/* <Row gutter={[16, 16]}>
          <Col xs={24} sm={16} md={16} lg={16} xl={16}>
            <div className='big'>
              Subscribe to our Newsletter
            </div>
          </Col>
          <Col xs={24} sm={8} md={8} lg={8} xl={8}>
            <Search
              className='subscribe'
              enterButton="Subscribe"
              size="large"
            />
          </Col>
        </Row> */}
        <Row gutter={[16, 16]}>
          <Col span={24}>&nbsp;</Col>
          <Col xs={24} sm={8} md={8} lg={8} xl={8}>
            <div className="box">
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <img
                    alt="home-banner"
                    src={require("../images/logo.png")}
                    className="img-responsive mini-logo"
                    style={{
                      backgroundColor: "black",
                      border: "1px solid #fff",
                    }}
                  />
                </Col>
                <Col span={24}>
                  Jalan Jambu No.51, Kel. Gondangdia,
                  <br />
                  Kec. Menteng, Central Jakarta 10350
                  <br />
                  <br />
                  Contact :<br />
                  Email : admin@thestrategist.id
                  <br />
                  Phone : 08111951518
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs={24} sm={8} md={8} lg={8} xl={8}>
            <div className="box-2">
              <Row gutter={[16, 0]}>
                <Col span={24}>
                  <Link to="/">Home</Link>
                </Col>
                <Col span={24}>
                  <Link to="/case-studies">Case Studies</Link>
                </Col>
                <Col span={24}>
                  <Link to="/teams">Who We Are</Link>
                </Col>
                <Col span={24}>
                  <Link to="/what-we-do">What We do</Link>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs={24} sm={8} md={8} lg={8} xl={8}>
            <div className="social">
              <Row gutter={[16, 8]}>
                <Col span={24}>
                  We bring the years, global experience, and stamina to guide
                  our clients through new and ofter disruptive realities.
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Footer>
      <Footer className="bottom">
        <div>Copyright @ 2022 The Strategist. All right reserved</div>
        <div className="menu">
          <span>Privacy Policy</span>
          <span>Cookie Policy</span>
        </div>
      </Footer>
    </div>
  );
};

export default FooterSection;
