import React, { useEffect, useState } from 'react';
import { Row, Col, Card } from 'antd';
import PropTypes from 'prop-types';
// import ProfileComponent from '../../components/profile';
import ModalAnt from '../../components/modalAnt';
import dataTeams from '../../services/teams';
import {
  LinkedinFilled,
  MailFilled
} from '@ant-design/icons';

const { Meta } = Card;

const AboutUs = () => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [personData, setPersonData] = useState(false);
  const [width] = useState(window.innerWidth);
  // const [ceoPerson, setCeoPerson] = useState([]);
  const [councilOfExpert, setCouncilOfExpert] = useState([]);
  const [seniorAdvisory, setSeniorAdvisory] = useState([]);
  const [consultingTeam, setConsultingTeam] = useState([]);
  const [administrationTeam, setAdministrationTeam] = useState([]);
  
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleClickDetail = (data) => {
    // if(width > 768) {
    //   return;
    // } else {
    //   setPersonData(data)
    //   showModal()
    // }
    setPersonData(data)
    showModal()
  }

  const [active, setActive] = useState(0);

  const [dataTabs] = useState([
    {
      id: 0,
      tabTitle: "Founder & CEO",
      tabClass: "myCustomClass",
      tabClicked: false
    },
    {
      id: 1,
      tabTitle: "Council of Expert",
      tabClass: "myCustomClass",
      tabClicked: false
    },
    {
      id: 2,
      tabTitle: "Senior Advisory Council",
      tabClass: "myCustomClass",
      tabClicked: false
    },
    {
      id: 3,
      tabTitle: "Consulting Team",
      tabClass: "myCustomClass",
      tabClicked: false
    },
    {
      id: 4,
      tabTitle: "Administration Team",
      tabClass: "myCustomClass",
      tabClicked: false
    }
  ]);

  const NavLink = ({ id, tabTitle, isActive, onClick }) => {
    return (
      <div
        onClick={() => navigate(id)}
        className={isActive ? "active" : ""}
      >
        {tabTitle}
      </div>
    );
  };

  const navigate = (id) => {
    setActive(id);
  };

  useEffect(() => {

    // Filter dataTeams data based on group
    // const ceoData = dataTeams.reduce(function(filtered, option) {
    //   if (option.group === "council-of-expert") {
    //      filtered.push(option);
    //   }
    //   return filtered;
    // }, []);
    // setCeoPerson(ceoData)
    
    const councilOfExpertData = dataTeams.reduce(function(filtered, option) {
      if (option.group === "council-of-expert") {
         filtered.push(option);
      }
      return filtered;
    }, []);
    //councilOfExpertData.shift()
    setCouncilOfExpert(councilOfExpertData)

    const seniorAdvisoryData = dataTeams.reduce(function(filtered, option) {
      if (option.group === "senior-advisory-council") {
         filtered.push(option);
      }
      return filtered;
    }, []);
    setSeniorAdvisory(seniorAdvisoryData)

    const consultingTeamData = dataTeams.reduce(function(filtered, option) {
      if (option.group === "consulting-team") {
         filtered.push(option);
      }
      return filtered;
    }, []);
    setConsultingTeam(consultingTeamData)

    const administrationTeamData = dataTeams.reduce(function(filtered, option) {
      if (option.group === "administration-team") {
         filtered.push(option);
      }
      return filtered;
    }, []);
    setAdministrationTeam(administrationTeamData)
  
  }, []);

  return (
    <div className='teams'>
      <Row className='team-subheader'>
        <Col span={24} xs={{ span: 24 }} lg={{ span: 24 }}> 
          <h1 className='title'>Local talents, <br />
          <span>global and intercultural</span> <br />
          experiences
          </h1>
          <div className='desc'>
            As the strategic communications consulting firm, we’re built to make strategic changes. To make strategic ideas connect with people.
          </div>
        </Col>
      </Row>
      <div className='teams-header'>
        {dataTabs.map((item) => (
          <li key={item.id}>
            <NavLink {...item} isActive={active === item.id} onClick={navigate} />
          </li>
        ))}
      </div>
      <div className='first-row'>
      { active === 0 && 
          (
            <>
              <Row gutter={[16, 16]} justify="center" className='ceo-section'>
                <Col md={14} lg={14} xl={14} className='left'>
                  <div className='name'>{dataTeams[0].name}</div>
                  <div className='position'>{dataTeams[0].position}</div>
                  <div className='desc'>
                    Andre is a communication expert in Indonesia. He was the Young Ambassador of UNESCO for Indonesia (2005), and the Chairman of Indonesian Broadcasting Commission (KPI) (2016-2019). Andre was elected to be the President of the World Broadcasting Commission in OIC (Organization of Islamic Cooperation) countries (2017-2018).
                  </div>
                  <div className='desc'>
                    Andre’s extensive educational pursuits include a Bachelor of Journalism Degree from Universitas Padjadjaran (2004), scholarship recipient for Master’s (2005-2007) and PhD in Mass Communication and Media Studies from Universiti Teknologi MARA (2010). Andre received an honorable award of Ten Outstanding Young  People (TOYP) (2013). Up to this day, Andre remains an active Program Coordinator of Masters of Communication Sciences at Universitas Andalas and a Doctorate promoter at multiple universities in Indonesia.
                  </div>
                  <div className='icon'>
                    <a href={`mailto:${dataTeams[0].email}`}><MailFilled /></a>
                    <a href={dataTeams[0].linkedIn} target="_blank" rel="noreferrer"><LinkedinFilled /></a>
                  </div>
                </Col>
                <Col md={10} lg={10} xl={10}>
                  <Card
                    className='card-content'
                    //onClick={() => handleClickDetail(dataTeams[0])}
                    cover={<img alt={dataTeams[0].name} src={dataTeams[0].pictureSquare} className="img-responsive" />}
                  >
                  </Card>
                </Col>
              </Row>
            </>
          )
        }
        { active === 1 && 
          (
            <>
              {/* <Row gutter={[8, 8]} justify="center">
                <Col md={10} lg={10} xl={8}>
                  <Card
                    className='card-content'
                    onClick={() => handleClickDetail(dataTeams[0])}
                    cover={<img alt={dataTeams[0].name} src={dataTeams[0].pictureSquare} className="img-responsive" />}
                  >
                    <Meta title={dataTeams[0].name} />
                    <Meta title={dataTeams[0].position} />
                  </Card>
                </Col>
              </Row> */}
              <Row gutter={[8,8]} justify="center">
                {councilOfExpert?.map((item, index) => (
                  <Col md={8} lg={8} xl={8} key={item.id}>
                    <Card
                      className='card-content'
                      onClick={() => handleClickDetail(councilOfExpert[index])}
                      cover={<img alt={item.name} src={item.pictureSquare} className="img-responsive" />}
                    >
                      <Meta title={item.name} />
                      <div className='role-position'>
                        {item.position}
                      </div>
                    </Card>
                  </Col> 
                ))}
              </Row>
            </>
          )
        }
        { active === 2 && 
          (
            <>
              <Row gutter={[8,8]} justify="center">
                {seniorAdvisory?.map((item, index) => (
                  <Col md={8} lg={8} xl={8} key={item.id}>
                    <Card
                      className='card-content'
                      onClick={() => handleClickDetail(seniorAdvisory[index])}
                      cover={<img alt={item.name} src={item.pictureSquare} className="img-responsive" />}
                    >
                      <Meta title={item.name} />
                      <div className='role-position'>
                        {item.position}
                      </div>
                    </Card>
                  </Col> 
                ))}
              </Row>
            </>
          )
        }
        { active === 3 && 
          (
            <>
              <Row gutter={[8,8]} justify="center">
                {consultingTeam?.map((item, index) => (
                  <Col md={8} lg={8} xl={8} key={item.id}>
                    <Card
                      className='card-content'
                      onClick={() => handleClickDetail(consultingTeam[index])}
                      cover={<img alt={item.name} src={item.pictureSquare} className="img-responsive" />}
                    >
                      <Meta title={item.name} />
                      <div className='role-position'>
                        {item.position}
                      </div>
                    </Card>
                  </Col> 
                ))}
              </Row>
            </>
          )
        }
        { active === 4 && 
          (
            <>
              <Row gutter={[8,8]} justify="center">
                {administrationTeam?.map((item, index) => (
                  <Col md={8} lg={8} xl={8} key={item.id}>
                    <Card
                      className='card-content'
                      onClick={() => handleClickDetail(administrationTeam[index])}
                      cover={<img alt={item.name} src={item.pictureSquare} className="img-responsive" />}
                    >
                      <Meta title={item.name} />
                      <div className='role-position'>
                        {item.position}
                      </div>
                    </Card>
                  </Col> 
                ))}
              </Row>
            </>
          )
        }
      </div>
      {/* <div className='second-row'>
        {
          dataPersonnel.map((dt, i) => {
            return (
              <ProfileComponent
                key={i}
                picture={dt.picture}
                name={dt.name}
                position={dt.position}
                description={dt.description}
                isCeo={i === 0}
                pictureLocation={(i + 1) % 2 === 0 ? 'right' : 'left'}
              />
            )
          })
        }
      </div> */}
      <ModalAnt 
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        showModal={showModal}
        handleOk={handleOk}
        handleCancel={handleCancel}
        checkWidth={width}
        data={personData}
      />
    </div >
  );
}

AboutUs.propTypes = {
  getHome: PropTypes.func,
  isLoading: PropTypes.bool,
  data: PropTypes.array,
}

export default AboutUs;