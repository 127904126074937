import React from 'react';

import { Col, Row, Modal } from 'antd';

const ModalContact = (props) => {

  const { handleOk, handleCancel, isModalOpen } = props;

  return (
    <div >
      <Modal visible={isModalOpen} onOk={handleOk} onCancel={handleCancel} width={800} wrapClassName='modal-contact'>
        <Row>
          <Col span={12}>
            <div className='form-contact'>
              <div style={{ fontSize: '30px', fontWeight: '800', marginBottom: '15px'}}>Contact Us</div>
              <div style={{padding: '10px', border: '1px solid #fff', marginBottom:'20px'}}>Phone No.</div>
              <div style={{padding: '10px', border: '1px solid #fff', marginBottom:'20px'}}>E-mail</div>
              <div style={{padding: '10px', border: '1px solid #fff', marginBottom:'20px'}}>Location of Company</div>
            </div>
          </Col>
          <Col span={12}>
            <div className="google-map-code">
              <iframe src="https://maps.google.com/maps?q=Jalan Jambu No.51, Kel. Gondangdia, Kec. Menteng, Central Jakarta 10350&t=&z=10&ie=UTF8&iwloc=&output=embed" width="400" height="343" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0" title="contact-us"></iframe>
            </div>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default ModalContact;
