import React from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';

const EveryoneCan = (props) => {
  return (
    <div className='ca-article'>
        <Row className='case-article'>
          <Col span={10} xs={{ span: 24 }} lg={{ span: 10 }}> 
            <div>
              <img src={require('../../images/dissemination-of-information.png')} className="img-responsive" alt="Now Everyone Can Take Parts" />
            </div>
          </Col>
          <Col span={14} xs={{ span: 24 }} lg={{ span: 14 }} className='right'> 
            <div>
              <div className='category'>Case Studies</div>
              <div className='title'>
                Dissemination of Information to Welcome Democratic Party
              </div>
              <div className='subtitle'>“Now Everyone Can Take Parts”</div>
            </div> 
          </Col>
        </Row>
        
        <Row className='ca-gray'>
          <Col span={24} xs={{ span: 24 }} lg={{ span: 24 }}> 
            <div className='title'>“Now Everyone Can Take Parts”</div>
            <div className='desc'>
              An Indonesian-owned and based nickel company aims to educate public about their product knowledge in preparation for the Initial Public Offering (IPO).
            </div>
          </Col>
        </Row>
        <Row className='ca-middle-section'>
          <Col span={12} xs={{ span: 24 }} lg={{ span: 12 }}> 
            <div className='title'>The Challenge</div>
            <div className='desc'>
              Our client intended to refresh their communications strategy to acquire public support towards the company's biggest agenda this year.
            </div>
          </Col>
          <Col span={12} xs={{ span: 24 }} lg={{ span: 12 }}> 
            <div className='title'>Our Approach</div>
            <div className='desc'>
              The Strategist conducted a thorough social audit and media relations to identify the strengths and areas of improvements of the client's existing communications strategy as a ground to develop a more integrated and relevant communications strategy to our client's target audiences.
            </div>
          </Col>
        </Row>
        <Row className='ca-gray'>
          <Col span={24} xs={{ span: 24 }} lg={{ span: 24 }}> 
            <div className='title'>“Now Everyone Can Take Parts”</div>
            <div className='desc'>
              The integrated communications strategy provided new insights to our client on how to produce creative contents that are tailor made to different behaviors of their audiences, as well as across multi platforms. It was approved by our client and adopted in their communications strategy to welcome the IPO.
            </div>
          </Col>
        </Row>

        <div className='ca-more'>
          <div className='main-title'>More Case Studies</div>
          <Row className='ca-more' gutter={[24,24]}>
            <Col span={8} xs={{ span: 24 }} lg={{ span: 8 }}> 
              <Link to="/nickel-around-us">
                <div>
                  <img src={require('../../images/more-case-studies-4.png')} className="img-responsive" alt="Now Everyone Can Take Parts" />
                </div>
                <div className='title'>Refreshing Communications Strategy to Support Initial Public Offering </div>
                <div className='subtitle'>”Nickel Around Us”</div>
              </Link>
            </Col>
            <Col span={8} xs={{ span: 24 }} lg={{ span: 8 }}> 
              <Link to="/towards-indonesia-2024">
                <div>
                  <img src={require('../../images/more-case-studies-1.png')} className="img-responsive" alt="Now Everyone Can Take Parts" />
                </div>
                <div className='title'>From Actors Mapping to Political Marketing</div>
                <div className='subtitle'>“Towards Indonesian 2024 Presidential Election”</div>
              </Link>
            </Col>
            <Col span={8} xs={{ span: 24 }} lg={{ span: 8 }}> 
              <Link to="/bapak-harmoni">
                <div>
                  <img src={require('../../images/more-case-studies-3.png')} className="img-responsive" alt="Now Everyone Can Take Parts" />
                </div>
                <div className='title'>Political Branding: Evolving Local to Regional Figure</div>
                <div className='subtitle'>“Bapak Digital, Bapak Harmoni”</div>
              </Link>
            </Col>
          </Row>
        </div>
    </div>
  );
}

export default EveryoneCan;