import React from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';

const BapakHarmoni = (props) => {
  return (
    <div className='ca-article'>
        <Row className='case-article'>
          <Col span={10} xs={{ span: 24 }} lg={{ span: 10 }}> 
            <div>
              <img src={require('../../images/political-branding.png')} className="img-responsive" alt="Bapak Digital, Bapak Harmoni" />
            </div>
          </Col>
          <Col span={14} xs={{ span: 24 }} lg={{ span: 14 }} className='right'> 
            <div>
              <div className='category'>Case Studies</div>
              <div className='title'>
                Political Branding: Evolving Local to Regional Figure
              </div>
              <div className='subtitle'>“Bapak Digital, Bapak Harmoni”</div>
            </div> 
          </Col>
        </Row>
        
        <Row className='ca-gray'>
          <Col span={24} xs={{ span: 24 }} lg={{ span: 24 }}> 
            <div className='title'>Bapak Digital, Bapak Harmoni</div>
            <div className='desc'>
              Our client's goal as a governor and politician is to gain national recognition for his ideas and initiatives through a comprehensive strategy to attract attention across the country despite potential obstacles: negative media coverage from oppositions, other politicians and interest groups.
            </div>
          </Col>
        </Row>
        <Row className='ca-middle-section'>
          <Col span={12} xs={{ span: 24 }} lg={{ span: 12 }}> 
            <div className='title'>The Challenge</div>
            <div className='desc'>
              Our client intended to refresh their communications strategy to acquire public support towards the company's biggest agenda this year.
            </div>
          </Col>
          <Col span={12} xs={{ span: 24 }} lg={{ span: 12 }}> 
            <div className='title'>Our Approach</div>
            <div className='desc'>
              The Strategist conducted a thorough social audit and media relations to identify the strengths and areas of improvements of the client's existing communications strategy as a ground to develop a more integrated and relevant communications strategy to our client's target audiences.
            </div>
          </Col>
        </Row>
        <Row className='ca-gray'>
          <Col span={24} xs={{ span: 24 }} lg={{ span: 24 }}> 
            <div className='title'>Bapak Digital, Bapak Harmoni</div>
            <div className='desc'>
              Our client responded positively, suggesting that strengthening the two identities can be adapted as most of the suggestions were constructive. Thus, consultation adaptation has been transformed into various strategies.
            </div>
          </Col>
        </Row>

        <div className='ca-more'>
          <div className='main-title'>More Case Studies</div>
          <Row className='ca-more' gutter={[24,24]}>
            <Col span={8} xs={{ span: 24 }} lg={{ span: 8 }}> 
              <Link to="/nickel-around-us">
                <div>
                  <img src={require('../../images/more-case-studies-4.png')} className="img-responsive" alt="Now Everyone Can Take Parts" />
                </div>
                <div className='title'>Refreshing Communications Strategy to Support Initial Public Offering </div>
                <div className='subtitle'>”Nickel Around Us”</div>
              </Link>
            </Col>
            <Col span={8} xs={{ span: 24 }} lg={{ span: 8 }}> 
              <Link to="/nickel-around-us">
                <div>
                  <img src={require('../../images/more-case-studies-1.png')} className="img-responsive" alt="Now Everyone Can Take Parts" />
                </div>
                <div className='title'>Dissemination of Information to Welcome the Democratic Party</div>
                <div className='subtitle'>”Now Everyone Can Take Parts”</div>
              </Link>
            </Col>
            <Col span={8} xs={{ span: 24 }} lg={{ span: 8 }}> 
              <Link to="/towards-indonesia-2024">
                <div>
                  <img src={require('../../images/more-case-studies-2.png')} className="img-responsive" alt="Now Everyone Can Take Parts" />
                </div>
                <div className='title'>From Actors Mapping to Political Marketing</div>
                <div className='subtitle'>“Towards Indonesian 2024 Presidential Election”</div>
              </Link>
            </Col>
          </Row>
        </div>
    </div>
  );
}

export default BapakHarmoni;