
const dataTeams = [
  {
    id: 1,
    name: "Yuliandre Darwis, Ph.D",
    position: "CEO & Founder",
    picture: require('../../images/personnels/andre.png'),
    pictureSquare: require('../../images/our-teams/yuliandre-darwis.png'),
    email: "yuliandre.darwis@thestrategist.id",
    linkedIn: "https://www.linkedin.com/in/yuliandre-darwis-ab4ab965/",
    group: "ceo",
    description: [
      "Andre is the CEO and Founder of The Strategist. He is a communication expert in Indonesia. Andre was the Young Ambassador of UNESCO for Indonesia in 2005. He was then entrusted to carry out the mandate of the Chairman of Indonesian Broadcasting Commission (KPI) for the 2016-2019 period at the age of 36, which made him the youngest Chairman of a State Institution.",
      "In 2017 and 2018 consecutively, Andre was elected to be the President of the World Broadcasting Commission in OIC (Organization of Islamic Cooperation) countries. Andre’s extensive educational pursuits include a Bachelor of Journalism Degree from the Universitas Padjadjaran Indonesia in 2004, scholarship recipient for a Master’s degree in 2005-2007, and a PhD in Mass Communication and Media Studies from the Universiti Teknologi MARA (UiTM) in 2010.",
      "In the academic realm, Andre received an honorable award of Ten Outstanding Young People (TOYP) from Junior Chamber International (JCI) in Academic Leadership 2013. Up to this day, Andre remains an active Program Coordinator of Masters of Communication Sciences at Universitas Andalas and a Doctorate promoter at multiple universities in Indonesia. Andre is also active in the creative economy and business industries. He became a producer in several films that aired on a big screen. Andre is also a part of the national’s professional and business organizations such as KADIN Indonesia, and BPP HIPMI."
    ]
  },
  {
    id: 2,
    name: "Prof. Dr. Hamdi Muluk, M.Si (FPsi)",
    position: "Professor of Social and Political Psychology, University of Indonesia",
    picture: require('../../images/personnels/hamdi-muluk.png'),
    pictureSquare: require('../../images/our-teams/hamdi-muluk.png'),
    email: "",
    linkedIn: "",
    group: "council-of-expert",
    description: [
      "As his current position as advisor to the government (expert panels of National Counter Terrorism Agency of Indonesia), Prof. Dr. Hamdi Muluk has conducted various academic and policy-based research concerning ethnic conflicts, political profiling of presidential candidates, religious fundamentalism, terrorism and other social issues. \n",
      "He has received many government grants for studying social-psychological aspects of religious fundamentalism, terrorism in Indonesia."  
    ]
  },
  {
    id: 3,
    name: "Prof. Deddy Mulyana MA., Ph.D",
    position: "Professor of Communication Studies, Padjajaran University",
    picture: require('../../images/personnels/deddy-mulyana.png'),
    pictureSquare: require('../../images/our-teams/deddy-mulyana.png'),
    email: "",
    linkedIn: "",
    group: "council-of-expert",
    description: [
      "Prof. Deddy Mulyana MA., Ph.D completed his bachelor's degree in Journalism from Padjajaran University (1981), earned an MA in Communication Studies from Northern Illinois University (1986), and completed his Doctor of Anthropology and Sociology from Monash University (1996). ",
      "Deddy has been a Researcher and Visiting Professor at Northern Illinois University USA, Randolph-Macon Woman's College USA, Technische Universitat Ilmenau Germany, Leiden University Netherlands, and Monash University Australia."
    ]
  },
  {
    id: 4,
    name: "Prof. Dr. Ibnu Hamad, M.Si",
    position: "Professor of Communication Studies, University of Indonesia",
    picture: require('../../images/personnels/ibnu-hamad.png'),
    pictureSquare: require('../../images/our-teams/ibnu-hamad.png'),
    email: "",
    linkedIn: "",
    group: "council-of-expert",
    description: [
      "Prof. Dr. Ibnu Hamad, M.Si completed his bachelor's degree in Communication Management (1991), earned an M.A. in International Communication (1997), and completed his Doctor of Communication Studies (2003) all from the same University of Indonesia. ",
      "His areas of communication expertises include: Semiotics and Media Discourse, Political Communications, and Forensic Communications."
    ]
  },
  {
    id: 5,
    name: "Prof. Dr. Phil. Hermin Indah Wahyuni, S.IP, M.Si",
    position: "Professor of Communication Studies, Gadjah Mada University",
    picture: require('../../images/personnels/phil-hermin.png'),
    pictureSquare: require('../../images/our-teams/phil-hermin.png'),
    email: "",
    linkedIn: "",
    group: "council-of-expert",
    description: [
      "Prof. Dr. Phil. Hermin Indah Wahyuni, S.I.P, M.Si was inaugurated as a Professor at Senate Hall of Gadjah Mada University in 2021. ",
      "Her autograph areas of communication expertises include: Communication and Broadcasting Policy, Political Communications, Media Regulations, Media Political Economy, Intercultural Communication, and Communication System."
    ]
  },
  {
    id: 6,
    name: "Prof. Rachmah Ida, Ph.D",
    position: "Professor of Communication Studies, Airlangga University",
    picture: require('../../images/personnels/rachmah-ida.png'),
    pictureSquare: require('../../images/our-teams/rachmah-ida.png'),
    email: "",
    linkedIn: "",
    group: "council-of-expert",
    description: [
      "In 2014, Prof. Rachmah Ida, Ph.D succeeded in becoming the first Professor of Media Studies in Indonesia. In 2022, she was awarded in the Alper-Doger (AD) Scientific Index's Top 100 Scientist Social Sciences. Her areas of communication expertises include: Media Studies, Islamic Studies, and Pop Culture."
    ]
  },
  {
    id: 7,
    name: "Dr. Anter Venus, MA.Comm",
    position: "Chancellor of UPN Veteran Jakarta",
    picture: require('../../images/personnels/anter-venus.png'),
    pictureSquare: require('../../images/our-teams/anter-venus.png'),
    email: "",
    linkedIn: "",
    group: "council-of-expert",
    description: [
      "Dr. Anter Venus, MA.Comm has handled a variety of communication jobs from government agencies, state-owned enterprises, private companies, to various NGOs and political parties. ",
      "His areas of communication expertises include: Communication Theory, Communication Philosophy, Campaign Management, Communication Audit, Organizational Culture & Communication, and Therapeutic Communication."  
    ]
  },
  {
    id: 8,
    name: "Prof. Dr Rajab Ritonga",
    position: "Professor of Communication Studies, Gunadarma University",
    picture: require('../../images/personnels/rajab-ritonga.png'),
    pictureSquare: require('../../images/our-teams/rajab-ritonga.png'),
    email: "",
    linkedIn: "",
    group: "council-of-expert",
    description: [
      "Prof.Dr Rajab Ritonga is a senior journalist and Professor of Communication Studies at Gunadarma University. ",
      "In 2007, he was assigned to be the Director of HR and General Affairs of the Antara National News Agency until 2012. His trademark areas of communication expertises include: Military Communications, War and Conflict Studies."
    ]
  },
  {
    id: 9,
    name: "Prof. Dr Asrinaldi, M.Si",
    position: "Professor of Social and Political Science, Universitas Andalas",
    picture: require('../../images/personnels/asrinaldi.png'),
    pictureSquare: require('../../images/our-teams/asrinaldi.png'),
    email: "",
    linkedIn: "",
    group: "council-of-expert",
    description: [
      "Prof. Asrinaldi is a Professor of Political Sciences at Andalas University. His written works are persistently discussing Indonesian regional and national political dynamics and have been published in various media. ",
      "His signature areas of communication expertises include: Policy Politics, Local Democracy, Decentralization."
    ]
  },
  {
    id: 10,
    name: "Prof. Widodo Muktiyo",
    position: "Professor of Social and Political Science, Universitas Sebelas Maret",
    picture: require('../../images/personnels/widodo-muktiyo.png'),
    pictureSquare: require('../../images/our-teams/widodo-muktiyo.png'),
    email: "",
    linkedIn: "",
    group: "council-of-expert",
    description: [
      "Prof. Dr. Widodo Muktiyo is a Professor of Political Sciences at Sebelas Maret University. He is an Alumnus of Communication Studies from Universitas Gadjah Mada, Faculty of Economics Universitas Islam Indonesia and OATS Alumnus in Osaka, Japan.",
      "In 2003, he was appointed Expert Staff to the UNS Chancellor in the field of Communication and Public Relations."

    ]
  },{
    id: 11,
    name: "Prof. Dr. Andi Alimuddin Unde, M.Si",
    position: "Professor of Communication Science, Hasanuddin University",
    picture: require('../../images/personnels/andi-alimuddin.png'),
    pictureSquare: require('../../images/our-teams/andi-alimuddin.png'),
    email: "",
    linkedIn: "",
    group: "council-of-expert",
    description: [
      "Prof. Dr. Andi is a Professor of Communication Science at Hasanuddin University. He completed his bachelor's degree in Communication Studies from Hasanuddin University, earned an M.A. in Mass Media from Padjajaran University, and completed his Doctor of Mass Media and Society at Hasanuddin University. Andi's areas of communication expertises include: Mass Media and Democracy, Mass Media and Pluralism, Media Exposure, Media Ecology, and Media Literacy Studies applied in national and international publications."
    ]
  },
  // {
  //   id: 9,
  //   name: "Josua Pardede",
  //   position: "Chief Economist, PermataBank",
  //   picture: require('../../images/personnels/josua-pardede.png'),
  //   pictureSquare: require('../../images/our-teams/josua-pardede.png'),
  //   email: "",
  //   linkedIn: "",
  //   group: "council-of-expert",
  //   description: [
  //     "Josua Pardede is currently the Chief Economist at PermataBank Indonesia and a teaching assistant at Graduate Program in Economics University of Indonesia.. He earned Bachelor of Science in Mathematics from University of Indonesia (2007), earned a MSc in Economics from the University of Amsterdam (2010) and MSc in Economics from University of Indonesia (2011). Josua's specialization areas include: Economics, Econometrics, monetary economics, financial economics, fixed income, banking industry and regional development economics."  
  //   ]
  // },
  {
    id: 12,
    name: 'Dicky Dwi Septian',
    position: 'Vice President of Creative',
    picture: require('../../images/our-teams/dicky-dwi-popup.png'),
    pictureSquare: require('../../images/our-teams/dicky-dwi.png'),
    email: "dicky@thestrategist.id",
    linkedIn: "",
    group: "senior-advisory-council",
    description: [
      "Graduated from Universitas Indonesia, Dicky has more than 7 years of extensive experience in communication and creative industries.",
      "He has worked in multinational agencies with specialization in creative content production"
    ]
  },
  {
    id: 13,
    name: 'Regina Jessica Angeline Purba',
    position: 'Vice President of Product',
    picture: require('../../images/personnels/regina.png'),
    pictureSquare: require('../../images/our-teams/regina-jessica.png'),
    email: "regina@thestrategist.id",
    linkedIn: "",
    group: "senior-advisory-council",
    description: [
      "Regina graduated from the Department of International Relations, Universitas Gadjah Mada, in an accelerated duration of 3 years.",
      "To date, she has 4+ years of strong experience across diverse fields, including educational institutions, business sectors and government agencies."
    ]
  },
  // {
  //   id: 12,
  //   name: 'Yasmin Nur Hajizah',
  //   position: 'Vice President of Marketing and Communications',
  //   picture: require('../../images/personnels/andre.png'),
  //   pictureSquare: require('../../images/our-teams/tim-stratgeis-yasmin-nur-hajizah.png'),
  //   email: "",
  //   linkedIn: "",
  //   group: "senior-advisory-council",
  //   description: [
  //     "Yasmin is the Vice President Marketing and Communications at The Strategist. She is routinely sought by clients to assist them in the matters of big data analysis.",
  //     "Her background remarks the extensive working experiences she had at Indonesian Police Headquarters. In 2020, she was specifically involved in the Crisis Management System for Covid-19. She did a trend analysis related to the security situation and crime types that could potentially occur as a result of the pandemic. Then, compiling counter narratives and literacy related to hoax news, propaganda, and black campaigns. Yasmin also gathered suggestions and strategic steps for the client based on data analysis in handling potential security threats and how to act according to the health protocols.",
  //     "The in-depth knowledge and acute big data analysis skills that Yasmin brings to the table has helped assist police function in taking care of strategic studies, policy studies, communication strategy based on the trending issues related to terrorism, election and crimes at the local, national and international levels.",
  //     "Yasmin also joined Digital Public Relation at Pertamina, where she was furthermore involved in conceptual strategy making using monitoring tools Media Intelligence. She also did media mapping, alert and tracking to deliver the content analysis. She detected negative issues and potential black campaigns using early warning system. All the works gathered she used for strategising the countermeasures for black campaigns on social media against Pertamina.",
  //     "Yasmin graduated from the Department of Political Science at Universitas Airlangga in 2012."
  //   ]
  // },
  {
    id: 14,
    name: 'Fadilla Embun Firdausy',
    position: 'Analyst',
    picture: require('../../images/our-teams/fadilla-embun-popup.png'),
    pictureSquare: require('../../images/our-teams/fadilla-embun.png'),
    email: "fadillausy@thestrategist.id",
    linkedIn: "",
    group: "consulting-team",
    description: [
      "Usy graduated cumlaude from the Department of Political Science, Universitas Indonesia. ",
      "She has carried her work in the media, communications and politics industries for more than 3 years and counting."
    ]
  },
  {
    id: 15,
    name: 'Evan Ihsan Fauzi',
    position: 'Analyst',
    picture: require('../../images/our-teams/evan-ihsan-popup.png'),
    pictureSquare: require('../../images/our-teams/evan-ihsan.png'),
    email: "evanfauzi@thestrategist.id",
    linkedIn: "",
    group: "consulting-team",
    description: [
      "A sharp and intelligent writer with research talent, Evan graduated cumlaude from the Department of Political Science, Universitas Indonesia. ",
      "He has 3 years of solid experience in research and writing, and will continue to explore and create interesting stories with his pen, communicating the findings with accuracy and fluency."
    ]
  },
  {
    id: 16,
    name: 'Bianca Michelle Datubara',
    position: 'Corporate Secretary',
    picture: require('../../images/our-teams/bianca-michelle-popup.png'),
    pictureSquare: require('../../images/our-teams/bianca-michelle.png'),
    email: "bianca@thestrategist.id",
    linkedIn: "",
    group: "administration-team",
    description: [
      "Bianca is a Master graduate of Communication Science at Universitas Indonesia.",
      "For 6+ years, she has been intensively working in the fields of media relations and government."
    ]
  },
  {
    id: 17,
    name: 'Betty Nurbayti',
    position: 'Corporate Secretary',
    picture: require('../../images/our-teams/betty-nurbayti-popup.png'),
    pictureSquare: require('../../images/our-teams/betty-nurbayti.png'),
    email: "betty@thestrategist.id",
    linkedIn: "",
    group: "administration-team",
    description: [
      "Betty graduated from the Faculty of Communication Studies, Universitas Pancasila.",
      "With more than 13 years of experience, she has carried work in strategic communications and  marketing."
    ]
  },
  // {
  //   name: 'Andy Budiman',
  //   position: 'Managing Director',
  //   picture: require('../../images/personnels/andre.png'),
  //   description: [
  //     "Andy is the Managing Director of The Strategist. He is an expert who specializes in Public Communication Strategies.",
  //     "With a background in journalism and activism, Andy accumulated a wealth of local and international working experiences, training, and public speaking. He has an extensive network of academics, intellectuals, activists, journalists, humanists, and politicians.",
  //     "As a journalist, Andy founded Kantor Berita 68h Utan Kayu and became the Head of Editor. He hosted and produced the talk show Topik Minggu Ini, and Barometer in Liputan6 SCTV, which ranked the highest in national talk show during that period. In Germany, he was appointed as the Editor in Deutsche Welle (DW). He was awarded scholarships, including the Jefferson Fellowship from East West Center, US, and the International Academy of Leadership, from Friedrich Naumann Stiftung, Germany in 2010.",
  //     "As a public speaker, Andy was invited to speak in numerous international conferences. Among the many, there are Covering Each Other in the era of Imagined Clashed of Civilization in The University of Lahore, Pakistan, and Asia Liberty Forum discussing The Role of Internet: Challenges to Liberty Online.",
  //     "As an activist, he founded Serikat Jurnalis untuk Keberagaman (SEJUK), and served as head of Aliansi Jurnalis Independen (AJI Indonesia), also as the founder of Humanitarian Commission for Emergency Response for Tsunami Aceh 2004.",
  //     "Andy graduated from the Faculty of Communication Sciences, Universitas Padjadjaran. Currently, he is serving as the President’s law expert staff assistant."
  //   ]
  // },
  // {
  //   name: 'Faldo Maldini',
  //   position: 'Operations Director',
  //   picture: require('../../images/personnels/andre.png'),
  //   description: [
  //     "Faldo is the Operations Director of The Strategist. He is astute in political communication. On 14 July 2021, Faldo was directly appointed by none other than the Minister of State Secretariat of Indonesia, as the Special Staff of the Minister of State for Media and Communication. Which makes him the Spokesperson for the Government of Indonesia.",
  //     "In his university years, Faldo gained an extensive student activist background. He was entrusted with being the Chair of the Student Executive Board, Universitas Indonesia (BEM UI). He was also elected as the Chairperson of the Indonesian Student Association in the United Kingdom (PPI UK). All these experiences combined have honed his skills in gathering public support.",
  //     "Prior to politics and communication, Faldo also has years of experience in professional and business sectors. He joined the Business Development and Risk Management Committee on the Board of Commissioners of PT Garuda Indonesia, Tbk. He owns several business units that focus on the manufacture of innovative products with added values in the field of technology and information.",
  //     "Faldo graduated from the Department of Physics, Universitas Indonesia. He holds two Master’s degrees in Physics from Imperial College London, and Political Sciences from Universitas Indonesia.",
  //   ]
  // },
  // {
  //   name: 'Wawan',
  //   position: 'Security',
  //   picture: require('../../images/personnels/andre.png'),
  //   description: [
  //     "Wawan is the Security at The Strategist. This man from Tegal, Central Java has years of experience in the security sector. In the hands of Wawan, security issues will be well maintained."
  //   ]
  // },
];

export default dataTeams;