import {React, useState} from 'react';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import ModalAntCapabilities from '../../components/modalAntCapabilities';

const dataOurValues = [
  {
    title: 'Agility',
    picture: require('../../images/about-us/agility.png'),
    description: "In a fast-paced world, we understand and put our client's needs at the center yet allow greater responsiveness through our collaborative teamwork."
  },
  {
    title: 'Fairness',
    picture: require('../../images/about-us/fairness.png'),
    description: 'At all times, our clients and stakeholders are treated with fairness where their concerns are equally considered.'
  },
  {
    title: 'All Level Networking',
    picture: require('../../images/about-us/networking.png'),
    description: 'Our network spans across government, political actors, mainstream media, social media influencers, NGO activists, academicians, researchers and religious leaders at local and national levels.'
  },
  {
    title: 'Professional Excellence',
    picture: require('../../images/about-us/professional.png'),
    description: "Our consistency in our team's development knowledge and skills delivers the best quality and innovative tailor-made services to our clients."
  },
]

const dataOurCommitment = [
  {
    title: 'Business Wise',
    description: "We focus on our client and the business situation. Our team members are fully and responsibly dedicated to accompany each and every of our client's journey in a personal client focused way."
  },
  {
    title: 'Healthy Ecosystem',
    description: "In fulfilling our client's needs and interests, we concurrently consider mutual benefits and solutions that work for all respective stakeholders at local and national levels."
  },
  {
    title: 'Excellent Delivery',
    description: "We constantly strive towards excellence in taking care of our client's needs through responsive and collaborative approaches. This approach has won us an impressive list of clients in their respective fields, including natural resources, government institutions, and political actors."
  },
  {
    title: 'Digitalization',
    description: "In this era, we continually take digitalization as an opportunity. We offer the best and tailored solutions to our clients through productivity and simplicity at the same as being more environment-friendly."
  },
]

const dataOurCapability = [
  {
    title: 'Maintaining',
    picture: require('../../images/about-us/maintain.jpg'),
    pictureMobile: require('../../images/about-us/maintain-mobile.jpg'),
    pictureRed: require('../../images/about-us/maintain-red.jpg'),
    description: "Maintaining the stability of organizational or business activities is the goal. The Strategist comes with Retainer capabilities to ensure your business stays up to date. Not only that but various activities are also aimed at keeping you away from potential crises that may come at any time."
  },
  {
    title: 'Crisis',
    picture: require('../../images/about-us/crisis.jpg'),
    pictureMobile: require('../../images/about-us/crisis-mobile.jpg'),
    pictureRed: require('../../images/about-us/crisis-red.jpg'),
    description: 'The Strategist is here to be your partner in crisis. Driven by data, reliable analysis, is to be connected to various local and national networks. Our crisis approach is integrated to prevent, prepare, respond and mitigate the crisis landscape that occurs. We aresensitive to distractions that change many of the "rules of the game".'
  },
  {
    title: 'Post Crisis',
    picture: require('../../images/about-us/post-crisis.jpg'),
    pictureMobile: require('../../images/about-us/post-crisis-mobile.jpg'),
    pictureRed: require('../../images/about-us/post-crisis-red.jpg'),
    description: "Once the crisis is over, The Strategist will move in full force to help you move normally as before the crisis. Post-crisis activities will lead to the return of stakeholder trust in you so that everything returns to normal."
  },
]

const AboutUs = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [capabilitiesData, setCapabilitiesData] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleClickDetail = (data) => {
    setCapabilitiesData(data)
    showModal()
  }

  return (
    <div className='aboutus content-layout'>
      <div className='first-row grey-bg'>
        <Row gutter={8} justify="space-between">
          <Col span={10}>
            <div className='main-title'>About</div>
            <div className='main-title'>Us</div>
          </Col>
          <Col span={14}>
            <div className='sub-title'>The Strategist is a strategic communications consulting firm.</div>
            <div className='about'>
              <p> We are here to assist those who make it possible! </p>
              <p> We aspire to help our clients go through realities so </p>
              <p> They can takeon challenges and build stronger reputations! </p>
            </div>
          </Col>
        </Row>
      </div>
      <div className='our-mission white-bg'>
        <Row gutter={8} justify="space-between">
          <Col span={12} xs={{ span: 24 }} lg={{ span: 12 }}>
            <div className='ctm-right-border'>
              <div className='sub-title'>Our Mission</div>
              <div className='about'>
                <p> To direct </p>
                <p> strategic solutions </p>
                <p> in the changing world </p>
              </div>
            </div>
          </Col>
          <Col span={12} xs={{ span: 24 }} lg={{ span: 12 }}>
            <div className='ctm-no-border'>
              <div className='sub-title'>Our Vision</div>
              <div className='about'>
                <p> To Provide variety of practices to </p>
                <p> strategic solutions </p>
                <p> in the changing world </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className='unique-values grey-bg'>
        <Row gutter={8} justify="space-between">
          <Col span={12} xs={{ span: 24 }} lg={{ span: 12 }}>
            <div className='hide-desktop' style={{ margin: '15px 0'}}>
              <img src={`${require('../../images/bg-together.jpg')}`} alt="thestrategist - journey together" />
            </div>
            <div className='sub-title'>
              The Strategist is built upon unique values that hold us together in this journey.
            </div>
            <div className='about'>
              <p> We take our key values acutely and in result. The Strategist has policies and procedures in place to make sure we do our best to practice what we preach. </p>
            </div>
          </Col>
          <Col span={12} xs={{ span: 24 }} lg={{ span: 12 }}>
            <img className="hide-mobile" src={`${require('../../images/bg-together.jpg')}`} alt="thestrategist - journey together" />
          </Col>
        </Row>
      </div>
      <div className='our-values grey-bg' style={{ borderTop: '30px solid #fff' }}>
        <div className='main-title'>Our Values</div>
        <Row gutter={[8,20]} justify="space-between" style={{ margin: '30px 0'}}>
              {
                dataOurValues.map((data, index) => {
                  return (
                    <Col span={12} xs={{ span: 24 }} lg={{ span: 12 }}>
                      <Row gutter={[8,8]} justify="space-between">
                        <Col span={6}>
                          <img src={data.picture} style={{ maxWidth: '100%', padding: '20px 0'}} alt={data.title} />
                        </Col>
                        <Col span={18}>
                          <div className='sub-title-sm'>{data.title}</div>
                          <p>{data.description}</p>
                        </Col>
                      </Row>
                    </Col>
                  )
                })
              }
        </Row>
      </div>
      <div className='our-commitment white-bg'>
        <div className='hide-desktop'>
          <img src={`${require('../../images/our-commitment-bg-mobile.png')}`} alt="thestrategist - commitment" />
        </div>
        <div className='main-title hide-desktop' style={{ textAlign: 'center', marginTop: '10px'}}>Our Commitment</div>
        <Row gutter={8} justify="space-between">
          <Col span={5} xs={{ span: 0 }} lg={{ span: 5 }}>
            <img src={`${require('../../images/about-us/commitment.jpg')}`} alt="thestrategist - commitment" />
          </Col>
          <Col span={18} xs={{ span: 24 }} lg={{ span: 18 }}>
            <div className='main-title hide-mobile'>Our</div>
            <div className='main-title hide-mobile'>Commitment</div>
            <Row gutter={8} justify="space-between" className='our-comm-sub'>
              {
                dataOurCommitment.map((data, index) => {
                  return (
                    <Col span={12} xs={{ span: 24 }} lg={{ span: 12 }}>
                      <div className='sub-title'>{data.title}</div>
                      <p>{data.description}</p>
                    </Col>
                  )
                })
              }
            </Row>
          </Col>
        </Row>
      </div>
      <div className='our-capabilities grey-bg'>
        <div className='main-title'>Our Capabilities</div>
        <Row gutter={25} justify="space-between">
          {
            dataOurCapability.map((data, index) => {
              return (
                <Col span={8} xs={{ span: 24 }} lg={{ span: 8 }}>
                  <div className='hide-mobile'>
                    <div>
                      <img src={data.picture}  style={{ maxWidth: '100%' }} alt={data.title} />
                    </div>
                    <div className='sub-title'>{data.title}</div>
                    <p>{data.description}</p>
                  </div>
                  <div className='hide-desktop'>
                    <div href='https://google.com'
                    onClick={() => handleClickDetail(data)}
                    >
                      <img src={data.pictureMobile}  style={{ maxWidth: '100%' }} alt={data.title} />
                    </div>
                  </div>
                </Col>
              )
            })
          }
        </Row>
      </div>

      <ModalAntCapabilities
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          showModal={showModal}
          handleOk={handleOk}
          handleCancel={handleCancel}
          data={capabilitiesData}
        />

    </div >
  );
}

AboutUs.propTypes = {
  getHome: PropTypes.func,
  isLoading: PropTypes.bool,
  data: PropTypes.array,
}

export default AboutUs;