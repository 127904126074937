import React, { useState } from 'react';
import { Layout, Menu, Button, Dropdown } from 'antd';
import { DownOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import ModalContact from './contact';
import { useLocation } from 'react-router-dom'

const { Header } = Layout;

const extraMenu = (

  <Menu
    className="menu-mobile"
  >
    {/* <Menu.Item key="1" className='menuItem'><Link to="/">Home</Link></Menu.Item>
    <Menu.Item key="2" className='menuItem'><Link to="/about-us">About US</Link></Menu.Item>
    <Menu.Item key="3" className='menuItem'><Link to="/teams">Teams</Link></Menu.Item>
    <Menu.Item key="4" className='menuItem'>Human Resources</Menu.Item>
    <Menu.Item key="6" className='menuItem'><Link to="/our-services">Our Services</Link></Menu.Item> */}
    <Menu.Item key="1" className='menuItem'><Link to="/">Home</Link></Menu.Item>
    <Menu.Item key="2" className='menuItem'><Link to="/case-studies">Case Studies</Link></Menu.Item>
    <Menu.Item key="3" className='menuItem'><Link to="/teams">Who We Are</Link></Menu.Item>
    <Menu.Item key="4" className='menuItem'><Link to="/what-we-do">What We do</Link></Menu.Item>
    <Menu.Item key="5" className='menuItem'>
      <Button className="topbar-contactus">Contact Us</Button>
    </Menu.Item>
  </Menu>
)

// const handleClickScroll = (id) => {
//   const element = document.getElementById('case-studies');
//   if (element) {
//     // 👇 Will scroll smoothly to the top of the next section
//     element.scrollIntoView({ behavior: 'smooth' });
//   }
// };

const HeaderMenu = () => {
  const [isActive, ChangeIsActive] = useState(false);
  const [isModalContactOpen, setIsModalContactOpen] = useState(false);

  const showModalContact = () => {
    setIsModalContactOpen(true);
  };

  const handleOkContact = () => {
    setIsModalContactOpen(false);
  };

  const handleCancelContact = () => {
    setIsModalContactOpen(false);
  };

  const blackHeader = [ 'case-studies', 'teams' ]
  let headerClass

  const location = useLocation();
  console.log(location, 'lokasi')

  if (blackHeader.some(v => location.pathname.includes(v))) {
    headerClass = 'header-top header-black'
  } else {
    headerClass = 'header-top'
  }

  return (
    <Header
      // style={{
      //   backgroundColor: '#ffffff',
      //   height: 'auto',
      //   display: 'flex',
      //   justifyContent: 'space-between'
      // }}
      className={headerClass}
    >
      <div className='logo-topbar'>
        <a href="/">
          <img
            alt="home-banner" src={require('../images/logo.png')} className="img-responsive img-logo"
            style={{
              backgroundColor: 'black'
            }}
          />
        </a>
      </div>
      <Menu
        className="top-menu"
        mode="horizontal"
        style={{
          //backgroundColor: '#ffffff',
          justifyContent: 'end',
          height: 'auto'
        }}
      >
        <Menu.Item key="1" className='menuItem'><Link to="/">Home</Link></Menu.Item>
        <Menu.Item key="2" className='menuItem'><Link to="/case-studies">Case Studies</Link></Menu.Item>
        <Menu.Item key="3" className='menuItem'><Link to="/teams">Who We Are</Link></Menu.Item>
        <Menu.Item key="4" className='menuItem'><Link to="/what-we-do">What We Do</Link></Menu.Item>
        <Menu.Item key="5" className='menuItem'>
          <Button className="topbar-contactus" onClick={() => showModalContact()}>Contact Us</Button>
        </Menu.Item>
      </Menu>
      <div className='mobilemenu'>
        <Dropdown
          overlay={extraMenu}
          placement="bottomRight"
          onVisibleChange={(val) => ChangeIsActive(val)}
        >
          <Button
            icon={isActive ? <UnorderedListOutlined style={{fontSize:18,fontWeight:400}} /> : <DownOutlined style={{fontSize:18,fontWeight:400}} />}
            style={{
              border: 'none',
            }}
          />
        </Dropdown>
      </div>

      <ModalContact 
        isModalOpen={isModalContactOpen}
        setIsModalOpen={setIsModalContactOpen}
        showModal={showModalContact}
        handleOk={handleOkContact}
        handleCancel={handleCancelContact}
      />
    </Header>
  );
}

export default HeaderMenu;