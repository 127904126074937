import React, { useState } from "react";
import { Row, Col } from "antd";
import PropTypes from "prop-types";
import ModalWhatWeDo from "../../components/modalWhatWeDo";

// const dataCaseStudies = [
//   {
//     id: 1,
//     title: 'Nickel Around Us',
//     image: require('../../images/case-studies/nickel-around-us.png'),
//     description: 'Name of client withheld as The Strategist is bound by Non-disclosure Agreement',
//     link: '/nickel-around-us'
//   },
//   {
//     id: 2,
//     title: 'Everyone Can Take Parts',
//     image: require('../../images/case-studies/everyone-can-take-parts.png'),
//     description: 'Name of client withheld as The Strategist is bound by Non-disclosure Agreement',
//     link: '/everyone-can-take'
//   },
//   {
//     id: 3,
//     title: 'Towards Indonesia in 2024',
//     image: require('../../images/case-studies/towards-indonesia-in-2024.png'),
//     description: 'Name of client withheld as The Strategist is bound by Non-disclosure Agreement',
//     link: '/towards-indonesia-2024'
//   },
//   {
//     id: 4,
//     title: 'Bapak Digital, Bapak Harmoni',
//     image: require('../../images/case-studies/bapak-digital-bapak-harmoni.png'),
//     description: 'Name of client withheld as The Strategist is bound by Non-disclosure Agreement',
//     link: '/bapak-harmoni'
//   },
// ]

// const dataWhatWeDo = [
//   {
//     id: 1,
//     title: 'Consulting',
//     image: require('../../images/consulting.png'),
//     description: 'The Strategist believes lobbying is key to achieve success. Between stakeholders there are common goals. We will help analyzing and provide strategic recommendations based on our internal data and insights. For that reason, we will ensure our client is able to maintain the relations in our fields of signature expertise, that are media, community and government.',
//     subDesc: [
//       {
//         title: 'Lobbying',
//         description: 'Lobbying is key to achieve success. Between stakeholders there are common goals. For that reason, we will ensure our clients are able to maintain the relations in our fields of signature expertise, that are media, community and government.'
//       },
//       {
//         title: 'In-depth analysis',
//         description: 'We will combine the outputs of our lobbying with our internal data intelligence to deliver more in-depth analysis for the benefits of our clients.'
//       },
//       {
//         title: 'Strategic recommendations',
//         description: 'We will gather our lobbying results with the data-driven analysis to produce strategic recommendations so help our clients can take steps forward.'
//       },
//     ]
//   },
//   {
//     id: 2,
//     title: 'Branding',
//     image: require('../../images/branding.png'),
//     description: 'The Strategist believes lobbying is key to achieve success. Between stakeholders there are common goals. We will help analyzing and provide strategic recommendations based on our internal data and insights. For that reason, we will ensure our client is able to maintain the relations in our fields of signature expertise, that are media, community and government.',
//     subDesc: [
//       {
//         title: 'Media strategy and analytics',
//         description: 'From research and data analytics, issue clusterization, stakeholders mapping to early warning system, we help our clients push boundaries on the path to achieving measurable results including media buying.'
//       },
//       {
//         title: 'Event and coaching',
//         description: 'We are built to help the C-level design and strengthen culture to change management, employee experience and more through summit, seminar, public discussion, gathering and luncheon.'
//       },
//       {
//         title: 'Creative, design and content',
//         description: 'We do it all - from audio-visual creative concepting, content development and production including influencer marketing, as well as social media management. We develop and execute ideas that earn attention and drive business impact.'
//       },
//     ]
//   },
// ]

const Home = () => {
  const [isModalWhatWeDoOpen, setIsModalWhatWeDoOpen] = useState(false);
  const [detailWhatWeDo, setDetailWhatWeDo] = useState("");

  const showModalWhatWeDo = (data) => {
    setIsModalWhatWeDoOpen(true);
    setDetailWhatWeDo(data);
  };

  const handleOkWhatWeDo = () => {
    setIsModalWhatWeDoOpen(false);
  };

  const handleCancelWhatWeDo = () => {
    setIsModalWhatWeDoOpen(false);
  };

  return (
    <div className="bg-black y-white-line">
      <div className="first hero-banner">
        <Row gutter={8}>
          <Col span={24}>
            <Row gutter={[16, 16]}>
              <Col sm={24} md={10} lg={10} xl={10} className="col-we-are">
                <div className="left">
                  <div className="sub-title">
                    {/* We are the <br />
                    <span>strategic</span> <br />
                    <span>communications</span> <br />
                    consulting firm */}
                    Turning bold <br />
                    ideas into <br />
                    <span>strategic action</span>
                  </div>
                </div>
              </Col>
              <Col sm={24} md={14} lg={14} xl={14}>
                <img
                  alt="home-banner"
                  src={require("../../images/andre-work-at-strategist.png")}
                  className="img-responsive"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      {/* <div className="strategic-action" id="strategic-action">
        <h3>
          Turning bold ideas into <br />
          <span>strategic action</span>
        </h3>
        <div>
          We work at the intersection of technology, society, policy and media,
          adding value to culture - to shape and re-shape it.
        </div>
      </div>

      <div className="work-at-strategist">
        <Row>
          <Col span={10} className="work-left">
            <div className="grow">#GrowWithTheStrategist</div>
            <div className="work-at">
              Work at <br /> The Strategist
            </div>
            <div>
              <Button className="join-us" onClick="">
                Join The Team
              </Button>
            </div>
          </Col>
          <Col span={14} className="work-right">
            <img
              alt="work-at-strategist"
              src={require("../../images/andre-work-at-strategist.png")}
              className="img-responsive"
            />
          </Col>
        </Row>
      </div> */}

      {/* <div className='second'>
        <Row gutter={[32, 16]}>
          <Col span={24}>
            <div className='top'>
              <div className='first'>
                Terdiri dari ahli strategi komunikasi, investigator, penulis, content creator, dan social media specialist.
              </div>
              <div className='second'>
                Siap membantu anda keluar dari <span className='bold'>KRISIS</span>.
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6}>
            <div className='card'>
              <div className='image'>
                <img alt="example" src={require('../../images/symbol_cc.png')} className="img-responsive" />
              </div>
              <div className='content'>
                <div className='title'>
                  Corporate Communication
                </div>
                <a href="/">Read More</a>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6}>
            <div className='card'>
              <div className='image'>
                <img alt="example" src={require('../../images/symbol_pa.png')} className="img-responsive" />
              </div>
              <div className='content'>
                <div className='title'>
                  Public Affairs
                </div>
                <a href="/">Read More</a>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6}>
            <div className='card'>
              <div className='image'>
                <img alt="example" src={require('../../images/symbol_ch.png')} className="img-responsive" />
              </div>
              <div className='content'>
                <div className='title'>
                  Crisis Handling
                </div>
                <a href="/">Read More</a>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6}>
            <div className='card'>
              <div className='image'>
                <img alt="example" src={require('../../images/symbol_gd.png')} className="img-responsive" />
              </div>
              <div className='content'>
                <div className='title'>
                  Going Digital
                </div>
                <a href="/">Read More</a>
              </div>
            </div>
          </Col>
        </Row>
      </div> */}

      {/* <div className='case-studies' id='case-studies'>
        <h2>Case Studies</h2>
        <Row gutter={24}>
        {
          dataCaseStudies.map((dt, i) => {
            return (
              <Col md={12} sm={24} key={dt.id}>
                <img src={dt.image} className='case-image' alt={dt.title}/>
                <div className='case-title'><a href={dt.link}>{dt.title}</a></div>
                <div className='case-desc'>{dt.description}</div>
              </Col>
            )
          })
        }
        </Row>
      </div> */}

      {/* <div className='what-we-do' id='what-we-do'>
        <h2>What We Do</h2>
        <Row gutter={24}>
        {
          dataWhatWeDo.map((dt, i) => {
            return (
              <Col md={12} sm={24}  key={dt.id}>
                <div className='do-container'>
                  <img src={dt.image} className='do-image' alt={dt.title}/>
                  <div className='do-title'>{dt.title}</div>
                  <div className='do-desc'>{dt.description}</div>
                  <button onClick={() => showModalWhatWeDo(dt)}>Learn More</button>  
                </div>
              </Col>
            )
          })
        }
        </Row>
      </div> */}

      {/* <Row gutter={8}>
        <div className="third">
          <Col span={24}>
            <Row gutter={8}>
              <Col sm={24} md={10} lg={10} xl={10}>
                <div className='image'>
                  <img
                    alt="home-banner" src={require('../../images/image_2.png')} className="img-responsive content"
                  />
                </div>
              </Col>
              <Col sm={24} md={14} lg={14} xl={14}>
                <div className='content'>
                  <div className='title-top'>
                    Jaringan kami
                  </div>
                  <div className='title-second'>
                    membentang di
                  </div>
                  <div className='sub-title'>
                    kalangan pemerintahan, para aktor politik, media mainstream, influencer social media, aktivis LSM, akademisi, peneliti, hingga tokoh agama di tingkat lokal maupun nasional.
                  </div>
                  <Button className='button'>
                    The Strategist Services
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </div>
        <Col span={24}>
          <div className='fourth'>
            <div className='title'>
              Kami membantu Anda mengatasi krisis
            </div>
            <div className='sub-title'>
              melalui tim terbaik kami
            </div>
          </div>
        </Col>
      </Row>

      <div className='fifth'>
        <Row gutter={8} justify="space-between">
          <Col lg={{ span: 6 }}>
            <div className='card'>
              <div className='image'>
                <img alt="example" src={require('../../images/symbol_digital.png')} className="img-responsive" />
              </div>
              <div className='content'>
                <div className='title'>
                  Era Digital dan Viral
                </div>
              </div>
            </div>
          </Col>
          <Col lg={{ span: 6 }}>
            <div className='card'>
              <div className='image'>
                <img alt="example" src={require('../../images/symbol_citra.png')} className="img-responsive" />
              </div>
              <div className='content'>
                <div className='title'>
                  Citra dan Persepsi
                </div>
              </div>
            </div>
          </Col>
          <Col lg={{ span: 6 }}>
            <div className='card'>
              <div className='image'>
                <img alt="example" src={require('../../images/symbol_usaha.png')} className="img-responsive" />
              </div>
              <div className='content'>
                <div className='title'>
                  Dunia Usaha dan Investasi
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div> */}
      <ModalWhatWeDo
        isModalOpen={isModalWhatWeDoOpen}
        setIsModalOpen={setIsModalWhatWeDoOpen}
        showModal={showModalWhatWeDo}
        handleOk={handleOkWhatWeDo}
        handleCancel={handleCancelWhatWeDo}
        data={detailWhatWeDo}
      />
    </div>
  );
};

Home.propTypes = {
  getHome: PropTypes.func,
  isLoading: PropTypes.bool,
  data: PropTypes.array,
};

export default Home;
