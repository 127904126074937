import React from 'react';

import { Modal } from 'antd';

const ModalAntCapabilities = (props) => {

  const { handleOk, handleCancel, isModalOpen, data } = props;

  return (
    <>
      <Modal visible={isModalOpen} onOk={handleOk} onCancel={handleCancel} className='detail-capability'
      bodyStyle={{ backgroundImage: `url(${data.pictureRed})`, backgroundColor: '#000'}}>
        <div>
          <div className='capability-title'>{data.title}</div>
          <div  className='capability-desc'>{data.description}</div>
        </div>
      </Modal>
    </>
  );
};

export default ModalAntCapabilities;
