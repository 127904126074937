import React from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';

const CaseStudies = (props) => {
  return (
    <div className='case-studies'>
        <Row gutter={[16, 16]} className='case-studies-top'>
          <Col span={12} xs={{ span: 24 }} lg={{ span: 12 }}> 
          <div className='case-studies-title'>
            <div>Strategic ideas</div>
            <div>that drive impact</div>
          </div>
          <div className='case-studies-sub-title'>
            <div>Helping brands and businesses create value</div>
            <div>for the communities that matter most – and</div>
            <div>driving value back into their organizations.</div>
          </div>
          </Col>
          <Col span={12} xs={{ span: 24 }} lg={{ span: 12 }}> 
          </Col>
        </Row>

        <Row className='case-article'>
          <Col span={12} xs={{ span: 24 }} lg={{ span: 12 }}> 
            <div>
              <img src={require('../../images/case-studies-1.png')} alt="Nickel Around Us" />
            </div>
          </Col>
          <Col span={12} xs={{ span: 24 }} lg={{ span: 12 }} className='right'> 
            <div>
              <div className='category'>Case Studies</div>
              <div className='title'>
                <Link to="/nickel-around-us">Refreshing Communications Strategy to Support Initial Public Offering</Link>
              </div>
              <div className='subtitle'>“Nickel Around Us”</div>
            </div> 
          </Col>
        </Row>
        <Row className='case-article'>
          <Col span={12} xs={{ span: 24 }} lg={{ span: 12 }} className='right'> 
            <div>
              <div className='category'>Case Studies</div>
              <div className='title'>
                <Link to="/everyone-can-take">Dissemination of Information <br /> to Welcome the <br /> Democratic Party</Link>
              </div>
              <div className='subtitle'>“Now Everyone Can Take Parts”</div>
            </div> 
          </Col>
          <Col span={12} xs={{ span: 24 }} lg={{ span: 12 }}> 
            <div>
              <img src={require('../../images/case-studies-2.png')} alt="Now Everyone Can Take Parts" />
            </div>
          </Col>
        </Row>
        <Row className='case-article'>
          <Col span={12} xs={{ span: 24 }} lg={{ span: 12 }}> 
            <div>
              <img src={require('../../images/case-studies-3.png')} alt="Towards Indonesian 2024 Presidential Election" />
            </div>
          </Col>
          <Col span={12} xs={{ span: 24 }} lg={{ span: 12 }} className='right'> 
            <div>
              <div className='category'>Case Studies</div>
              <div className='title'>
                <Link to="/towards-indonesia-2024">From Actors Mapping to Political Marketing</Link>
              </div>
              <div className='subtitle'>“Towards Indonesian 2024 Presidential Election”</div>
            </div> 
          </Col>
        </Row>
        <Row className='case-article'>
          <Col span={12} xs={{ span: 24 }} lg={{ span: 12 }} className='right'> 
            <div>
              <div className='category'>Case Studies</div>
              <div className='title'>
                <Link to="/bapak-harmoni">Political Branding: Evolving Local to Regional Figure</Link>
              </div>
              <div className='subtitle'>“Bapak Digital, Bapak Harmoni”</div>
            </div> 
          </Col>
          <Col span={12} xs={{ span: 24 }} lg={{ span: 12 }}> 
            <div>
              <img src={require('../../images/case-studies-4.png')} alt="Bapak Digital, Bapak Harmoni" />
            </div>
          </Col>
        </Row>
    </div >
  );
}

export default CaseStudies;