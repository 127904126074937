import React from 'react';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';

const dataServices = [
  {
    title: 'Issues Management',
    picture: require('../../images/our-services/issues.png'),
    descriptionList: ['Research & Data Analytics', 'Media Monitoring', 'Issue Clusterization', 'Stakeholders Mapping']
  },
  {
    title: 'Media Strategy',
    picture: require('../../images/our-services/media.png'),
    descriptionList: ['Media Actor Relations', 'Media Gathering', 'Spokesperson Training', 'Narration Building']
  },
  {
    title: 'Crisis Handling',
    picture: require('../../images/our-services/crisis.png'),
    descriptionList: ['Crisis Preparation', 'Produce Crisis SOP', 'Crisis Response', 'Reputation Recovery']
  },
  {
    title: 'Content Marketing',
    picture: require('../../images/our-services/content.png'),
    descriptionList: ['Creative Development', 'Content Production', 'Digital Storytelling']
  },
  {
    title: 'Political Affairs',
    picture: require('../../images/our-services/political.png'),
    descriptionList: ['State Actor Lobbying', 'Non-state Actor Lobbying', 'Strategic Consulting']
  },
  {
    title: 'Leader Positioning',
    picture: require('../../images/our-services/leader.png'),
    descriptionList: ['CEO Briefing', 'Inclusive Image Development', 'Personal Image Digital Management']
  },
  {
    title: 'Marketing Communications',
    picture: require('../../images/our-services/marketing.png'),
    descriptionList: ['Brand Strategy & Identity', 'Omnichannel Engagement', 'Distribution & Amplification Strategies']
  },
  {
    title: 'Corporate Reputation',
    picture: require('../../images/our-services/corporate.png'),
    descriptionList: ['Corporate Training', 'Rally Campaign', 'Event Management']
  },
  {
    title: 'Influencer Marketing',
    picture: require('../../images/our-services/influencer.png'),
    descriptionList: ['Integrated Influencer Strategy', 'Program Execution & Measurement', 'Custom Creator Content']
  },
  {
    title: 'Digital Transformation',
    picture: require('../../images/our-services/digital.png'),
    descriptionList: ['Go-to-market strategies', 'Customer experience', 'Product or service innovation']
  },
  {
    title: 'Data Security & Privacy',
    picture: require('../../images/our-services/data.png'),
    descriptionList: ['Analyze & Assess Risk', 'Front-End Preparedness', 'Live Breach Response']
  },
  {
    title: 'Environmental Social Governance',
    picture: require('../../images/our-services/environmental.png'),
    descriptionList: ['ESG Strategic Advisory', 'Planning & Execution', 'Reporting and Communications']
  },
  {
    title: 'Cross-Disciplinary Training',
    picture: require('../../images/our-services/cross.png'),
    descriptionList: ['PR Bureau Training', 'Creative Production Training', 'Field Activation Training', 'Digitalization Training']
  },
]

const OurServices = () => {
  return (
    <div className='our-services-page content-layout'>
      <div className='first-row'>
        <Row gutter={[16, 16]}>
          <Col span={12} xs={{ span: 24 }} lg={{ span: 12 }}> 
            <div className='main-title'>Our</div>
            <div className='main-title'>Services</div>
          </Col>
          {
            dataServices.map((data, index) => {
              return (
                <Col span={12} xs={{ span: 24 }} lg={{ span: 12 }}> 
                  <div className='detail-service'>
                    <div className='hide-desktop centered-icon c-text-center'>
                      <img src={data.picture} alt="thestrategist - commitment" className='service-icon' />
                    </div>
                    <div className='sub-title'>{data.title}</div>
                    <Row gutter={8}>
                      <Col span={16} xs={{ span: 24 }} lg={{ span: 16 }}> 
                        {
                          data.descriptionList.map((desc, i) => {
                            return (
                              <> •  {desc}<br /> </>
                            )
                          })
                        }
                      </Col>
                      <Col span={8} xs={{ span: 24 }} lg={{ span: 8 }} className='hide-mobile'> 
                        <img src={data.picture} alt="thestrategist - commitment" className='service-icon' />
                      </Col>
                    </Row>
                  </div>
                </Col>
              )
            })
          }          
        </Row>
      </div >
    </div >
  );
}

OurServices.propTypes = {
  getHome: PropTypes.func,
  isLoading: PropTypes.bool,
  data: PropTypes.array,
}

export default OurServices;