import React from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { Layout } from 'antd';

// components
import HeaderMenu from './components/header';
import FooterSection from './components/footer';
// screen
import HomeScreen from './screens/home';
import AboutUsScreen from './screens/aboutus';
import OurServicesScreen from './screens/ourservices';
import TeamsScreen from './screens/teams';
import CaseStudies from './screens/casestudies';
import NickelAround from './screens/nickelaround';
import EveryoneCan from './screens/everyonecantake';
import TowardIndonesia from './screens/towardindonesia';
import BapakHarmoni from './screens/bapakharmoni';
import WhatWeDo from './screens/whatwedo';

const { Content } = Layout;

function App() {
  return (
    <div className="App">
      <Layout className="main">
        <BrowserRouter>
          <HeaderMenu />
          <Content>
            <Routes>
              <Route path="/" element={<HomeScreen />} />
              <Route path="/about-us" element={<AboutUsScreen />} />
              <Route path="/our-services" element={<OurServicesScreen />} />
              <Route path="/teams" element={<TeamsScreen />} />
              <Route path="/case-studies" element={<CaseStudies />} />
              {/* <Route path="/case-studies/:id" element={<CaseStudies />} /> */}
              <Route path="/nickel-around-us" element={<NickelAround />} />
              <Route path="/everyone-can-take" element={<EveryoneCan />} />
              <Route path="/towards-indonesia-2024" element={<TowardIndonesia />} />
              <Route path="/bapak-harmoni" element={<BapakHarmoni />} />
              <Route path="/what-we-do" element={<WhatWeDo />} />
            </Routes>
          </Content>
          <FooterSection />
        </BrowserRouter>
      </Layout>
    </div>
  );
}

export default App;
