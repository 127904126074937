import React from 'react';
import { Row, Col, Modal } from 'antd';
import { MailFilled } from '@ant-design/icons';

const ModalAnt = (props) => {

  const { handleOk, handleCancel, isModalOpen, data } = props;

  console.log(data)

  return (
    <>
      <Modal 
        visible={isModalOpen} 
        onOk={handleOk}
        onCancel={handleCancel} 
        width={window.innerWidth > 425 ? 1000 : 520}
        className="profile-detail-modal"
       >
        { window.innerWidth > 425 ? (
            <div className='profile-detail profile-detail-desktop'>
              <Row justify="center">
                <Col md={10} lg={10} xl={10} className="left">
                  <img src={data.picture} alt={data.name} />
                </Col>
                <Col md={14} lg={14} xl={14} className="right" >
                  <div className='right-padding'>
                    <div className='profile-name'>{data.name}</div>
                    <div className='profile-position'>{data.position}</div>
                    {/* <div className='profile-desc'>{data.description}</div> */}
                    <div className='profile-desc'>
                      {data.description}
                    </div>
                    <div className='profile-social'>
                      { data.email &&
                        (
                          <a href={`mailto:${data.email}`}><MailFilled /></a>
                        )
                      }
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <div className='profile-detail mobile'>
              <img src={data.pictureSquare} alt={data.name} />
              <div className='profile-name'>{data.name}</div>
              <div className='profile-position'>{data.position}</div>
              <div className='profile-desc'>{data.description}</div>
            </div>
          )
        }
      </Modal>
    </>
  );
};

export default ModalAnt;
