import React from 'react';
import { Row, Col, Button } from 'antd';

const WhatWeDo = () => { 
  return (
    <div className='what-we-doing'>
      <Row gutter={[16, 16]} className='what-we-top'>
        <Col span={12} xs={{ span: 24 }} lg={{ span: 12 }}> 
        <div className='what-we-title'>
          <div>Strategic ideas</div>
          <div>that drive impact</div>
        </div>
        <div className='what-we-sub-title'>
          The nation’s leading organizations turn to The Strategist to
          deliver actionable impact. As client business models – and
          communications methods – adapt to new realities, we’re
          uniquely suited to bring together diverse talents, global insights
          and pioneering instincts to deliver industry-defining work
        </div>
        </Col>
        <Col span={12} xs={{ span: 24 }} lg={{ span: 12 }}> 
        </Col>
      </Row>
      <Row gutter={[16, 16]} className='our-expertise'>
        <Col span={24} xs={{ span: 24 }} lg={{ span: 24 }}> 
        <div className='title-red'> Our Expertise </div>
        <Row gutter={[16, 16]} className='our-expertise-sub'>
          <Col span={6} xs={{ span: 6 }} lg={{ span: 6 }}>
            <div className='our-exp'>
              <img src={require('../../images/exp-1.png')} alt='Media & Creative' />
              <div className='title'>Media & Creative</div>
              <Button>learn more</Button>
            </div>
          </Col>
          <Col span={12} xs={{ span: 12 }} lg={{ span: 12 }}>
            <div className='our-exp'>
              <img src={require('../../images/exp-2.png')} alt='Government & Public Sector' />
              <div className='title'>Government & Public Sector</div>
              <Button>learn more</Button>
            </div>
          </Col>
          <Col span={6} xs={{ span: 6 }} lg={{ span: 6 }}>
            <div className='our-exp'>
              <img src={require('../../images/exp-3.png')} alt='Non Profit' />
              <div className='title'>Non Profit</div>
              <Button>learn more</Button>
            </div>
          </Col>
        </Row>
        </Col>
      </Row>
      <Row gutter={[16, 16]} className='strategist-academy'>
        <Col span={24} xs={{ span: 24 }} lg={{ span: 24 }}> 
          The Strategist Academy
        </Col>
      </Row>
      <Row gutter={[16, 16]} className='sub-exp'>
        <Col span={24} xs={{ span: 24 }} lg={{ span: 24 }}> 
          <div className='our-exp-title'>Media & Creative</div>
          <Row gutter={[8, 8]} className='sub-exp-card'>
            <Col span={8} xs={{ span: 8 }} lg={{ span: 8 }}>
              <div>
                <div>Employee Branding:</div>
                <div>Establish an emotional connection and create good reputation.</div>
              </div>
            </Col>
            <Col span={8} xs={{ span: 8 }} lg={{ span: 8 }}>
              <div>
                <div>Copywriting:</div>
                <div>To help individuals convey messages to customers, stakeholders, prospects, and others.</div>
              </div>
            </Col>
            <Col span={8} xs={{ span: 8 }} lg={{ span: 8 }}>
              <div>
                <div>Leadership:</div>
                <div>Instill employees on board with the mission, values, and vision of the company.</div>
              </div>
            </Col>
          </Row>
          <div className='our-exp-title'>Government & Public Sectors</div>
          <Row gutter={[8, 8]} className='sub-exp-card'>
              <Col span={8} xs={{ span: 8 }} lg={{ span: 8 }}>
                <div>
                  <div>Spokesperson:</div>
                  <div>Prepare the participants to represent their organization.</div>
                </div>
              </Col>
              <Col span={8} xs={{ span: 8 }} lg={{ span: 8 }}>
                <div>
                  <div>Lobbying & Negotiation:</div>
                  <div>Equip the participants with the knowledge and skills to effectively lobby and negotiate with various stakeholders, such as policymakers and beneficiaries.</div>
                </div>
              </Col>
              <Col span={8} xs={{ span: 8 }} lg={{ span: 8 }}>
                <div>
                  <div>Actors Relations:</div>
                  <div>Help how to build and maintain positive and productive relations with other actors in their field.</div>
                </div>
              </Col>
            </Row>
          <div className='our-exp-title'>Non-Profit</div>
          <Row gutter={[8, 8]} className='sub-exp-card'>
              <Col span={8} xs={{ span: 8 }} lg={{ span: 8 }}>
                <div>
                  <div>Advocacy:</div>
                  <div>
                    Establish and maintain clear and compelling messages that effectively communicate the organization's value, vision, and position to NGO, ultimately driving positive social and political changes.
                  </div>
                </div>
              </Col>
              <Col span={8} xs={{ span: 8 }} lg={{ span: 8 }}>
                <div>
                  <div>Marketing & Communications:</div>
                  <div>Equip the participants with the knowledge and skills to effectively lobby and negotiate with various stakeholders, such as policymakers and beneficiaries.</div>
                </div>
              </Col>
              <Col span={8} xs={{ span: 8 }} lg={{ span: 8 }}>
                <div>
                  <div>Strategic Partnerships:</div>
                  <div>
                    Collaborate with other organizations that have complementary skills and resources, and to leverage their collective strengths for impactful nonprofit work that achieves the NGO’s mission and goals.
                  </div>
                </div>
              </Col>
            </Row>
        </Col>
      </Row>
      <Row gutter={[16, 16]} className='indonesia-strategic'>
        <Col span={24} xs={{ span: 24 }} lg={{ span: 24 }}> 
          Indonesia Strategic Forum
        </Col>
      </Row>
      <Row gutter={[16, 16]} className='we-do-bottom'>
        <Col span={24} xs={{ span: 24 }} lg={{ span: 24 }}> 
         <div>Indonesia Strategic Forum, an esteemed national event of paramount importance, is a grandiose forum that brings together luminaries, policymakers, and industry leaders from across the nation to deliberate on strategic issues that will shape Indonesia's future.</div>
         <div>Indonesia Strategic Forum is a beacon of hope and a symbol of Indonesia's commitment to excellence, a shining example of what can be achieved when the nation's brightest minds come together to tackle the challenges of the future.</div>
        </Col>
      </Row>
    </div>
  )
}

export default WhatWeDo;